import React from 'react';
import { Grid } from '@mui/material';
import styles from './main-features.module.scss';
import camera from '../../../../assets/images/CameraFeature.png';

import social from '../../../../assets/images/SocialFeature.png';
import {
  ChingariIcon,
  FaceBookIcon,
  InstagramIcon,
  JoshIcon,
  MainFeatureIcon1,
  MainFeatureIcon2,
  MainFeatureIcon3,
  MessengerIcon,
  MojIcon,
  ShareChatIcon,
  SnapchatIcon,
  TikTokIcon,
  WhatsAppIcon,
  XIcon,
} from '../../../../assets/images';

const MainFeatures: React.FC = () => {
  return (
    <section className={styles.container} id="features">
      <h2>Main Features</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={styles.boxItem}>
            <img
              className={styles.boxImage}
              src={MainFeatureIcon1}
              alt="Feature"
            />
            <div>
              <h4>Patented Camera - Available Now!</h4>
              <ul>
                <li>Fast & Slow Motion During Live Recording</li>
                <li>Zoom In & Out During Live Recording</li>
                <li>All with 1-Touch</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.boxItem}>
            <img
              className={styles.boxImage}
              src={MainFeatureIcon2}
              alt="Feature"
            />
            <div>
              <h4>Social Media</h4>
              <ul>
                <li>Quickly trim, cut and crop your videos</li>
                <li>Add voice-over narration</li>
                <li>Creator watermark protection</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.boxItem}>
            <img
              className={styles.boxImage}
              src={MainFeatureIcon3}
              alt="Feature"
            />
            <div>
              <h4>Make Money</h4>
              <ul>
                <li>
                  Share To Social Networks – TikTok, Instagram, Snapchat, X and
                  More
                </li>
                <div className={styles.iconsRow}>
                  <img src={TikTokIcon} alt="TikTok" />
                  <img src={FaceBookIcon} alt="Facebook" />
                  <img src={InstagramIcon} alt="Instagram" />
                  <img src={SnapchatIcon} alt="Snapchat" />
                  <img src={WhatsAppIcon} alt="WhatsApp" />
                  <img src={MessengerIcon} alt="Messenger" />
                  <img src={ShareChatIcon} alt="Sharechat" />
                  <img src={MojIcon} alt="Moj" />
                  <img src={ChingariIcon} alt="Chingari" />
                  <img src={JoshIcon} alt="Josh" />
                  <img src={XIcon} alt="X" />
                </div>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <p>Main feature description goes here</p> */}
      {/* <Grid container spacing={2} marginTop={1}>
        <Grid item md={6}>
          <b>Camera</b>
          <ul>
            <li>Fast & Slow Motion During Live Recording</li>
            <li>Zoom In & Out During Live Recording </li>
            <li>All with 1-Touch</li>
            <li>Built-In Video Editor </li>
            <li>Pic2Art Photo Filters (iPhone only)</li>
            <li>Create Unique and Fun Videos.</li>
          </ul>
        </Grid>
      </Grid> */}
      {/* <b>Social Media</b>
      <ul>
        <li>
          Share To Social Networks – TikTok, Instagram, Snapchat and More.
        </li>
        <li>
          <span>Gamification - Earn Badges and Gain Followers in QuickCam</span>
        </li>
      </ul>

      <b>Make Money</b>
      <ul>
        <li>
          Invite Wizard – Share QuickCam with Your Friends, Family and
          Followers.
        </li>
        <li>
          <span>
            Revenue Share – Make Money when They Sign Up and Subscribe!
          </span>
        </li>
        <li>
          <span>
            Compensation Plan - Earn from 8 Tiers and 5 Generations of Infinity
            Bonus
          </span>
        </li>
        <li>
          <span>
            QuickStart Bonus - Earn 50% from Personal Referrals First 3 Months
          </span>
        </li>
      </ul> */}
    </section>
  );
};

export default MainFeatures;
