// /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import React, { useEffect, useState } from 'react';
// import { Grid } from '@mui/material';
// import 'react-multi-carousel/lib/styles.css';
// import styles from './income-opportunity.module.scss';
// import RefMoney from '../../../../assets/images/MakeMoneyRef.png';
// import PersonalRef from '../../../../assets/images/MoneyPersonalRef.png';
// import SecondLevel from '../../../../assets/images/secondLevelRef.png';
// import SharingCaring from '../../../../assets/images/SharingCaring.png';
// // import styles from '../login.module.scss';
// // import { IncomeOpportunitiesBack, IOMobile } from '../../../../assets/svg';
// import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';

// const IncomeOpportunities = () => {
//   const { width } = useWindowDimensions();
//   return (
//     // <section
//     //   className={styles.incomOpportunitiesWrapper}
//     //   id="incomeOpportunities"
//     // >
//     //   <h2>Make Money Sharing QuickCam</h2>
//     //   {width > 767 ? (
//     //     <img src={IncomeOpportunitiesBack} alt="IncomeOpportunities" />
//     //   ) : (
//     //     <img src={IOMobile} alt="IncomeOpportunities" />
//     //   )}
//     // </section>

//     <section className={styles.container}>
//       <h2>Money Making Sharing QuickCam</h2>
//       <Grid container spacing={5}>
//         <Grid item xs={12} md={6}>
//           <div className={styles.box}>
//             <div>
//               <div className={styles.FirstBox}>
//                 <img src={RefMoney} alt="" />
//               </div>
//             </div>
//             <div>
//               <p className={styles.boxTitle}>Referral System</p>
//               Earn Monthly Passive Residual Income
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <div className={styles.box}>
//             <div>
//               <div className={styles.FirstBox}>
//                 <img src={PersonalRef} alt="" />
//               </div>
//             </div>
//             <div>
//               <p className={styles.boxTitle}>Personal Referrals</p>
//               Earn 50% first 3 months, Earn 25% thereafter
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <div className={styles.box}>
//             <div>
//               <div className={styles.FirstBox}>
//                 <img src={SecondLevel} alt="" />
//               </div>
//             </div>
//             <div>
//               <p className={styles.boxTitle}>2nd Level Referrals</p>
//               Earn 25% in the 4th month and thereafter
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <div className={styles.box}>
//             <div>
//               <div className={styles.FirstBox}>
//                 <img src={SharingCaring} alt="" />
//               </div>
//             </div>
//             <div>
//               <p className={styles.boxTitle}>Sharing is Caring</p>
//               Text, DM and Email your referral link
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//     </section>
//   );
// };

// export default IncomeOpportunities;
import React from 'react';
import { Grid } from '@mui/material';
import styles from './income-opportunity.module.scss';
import socialFiIcon1 from '../../../../assets/images/socialfi-icon1.png';
import socialFiIcon2 from '../../../../assets/images/socialfi-icon2.png';
import socialFiIcon3 from '../../../../assets/images/socialfi-icon3.png';
import socialFiIcon4 from '../../../../assets/images/socialfi-icon4.png';
import socialFiIcon5 from '../../../../assets/images/socialfi-icon5.png';
import socialFiIcon6 from '../../../../assets/images/socialfi-icon6.png';
import socialFiIcon7 from '../../../../assets/images/socialfi-icon7.png';
import socialFiIcon8 from '../../../../assets/images/socialfi-icon8.png';

const IncomeOpportunities = () => {
  return (
    <section className={styles.container}>
      {/* Header Banner */}
      <div className={styles.headerBanner}>
        <div className={styles.coinWrapper}>
          <img src={socialFiIcon7} alt="Quickie Coin" className={styles.coinImage} />
        </div>
        <div className={styles.headerContent}>
          <h2>Onboard to the Web3 Ecosystem</h2>
          <h3>Join the QuickCam SocialFi Network</h3>
        </div>
        <div className={styles.coinWrapper}>
          <img src={socialFiIcon8} alt="Speedy Coin" className={styles.coinImage} />
        </div>
      </div>

      {/* Feature Grid */}
      <Grid container spacing={3}>
        {/* First Row */}
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={styles.iconWrapper}>
              <img src={socialFiIcon1} alt="" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Creators Get Paid Directly 🚀</h4>
              <ul>
                <li>Fans & followers can tip, subscribe, and buy exclusive content</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={`${styles.iconWrapper} ${styles.customIconWrapper}`}>
                <img src={socialFiIcon2} alt="Community Meme Coins" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Earn Community Meme Coins With Upside Potential💰</h4>
              <li>Get crypto for liking, sharing, and posting content!</li>
            </div>
          </div>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={styles.iconWrapper}>
              <img src={socialFiIcon3} alt="Buy Sell Icon" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Buy and Sell Meme Coins 🔗</h4>
              <ul>
                <li>Buy & sell $Quickie and $Slowie and Influencer Meme Coins.</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={styles.iconWrapper}>
              <img src={socialFiIcon4} alt="Passive Income Icon" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Passive Residual Income</h4>
              <ul>
                <li>Refer QuickCam SocialFi to new subscribers and make money.</li>
              </ul>
            </div>
          </div>
        </Grid>

        {/* Third Row */}
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={styles.iconWrapper}>
              <img src={socialFiIcon5} alt="Participate Icon" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Anyone can participate!</h4>
              <ul>
                <li>QuickCam subscription is not required.</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.featureBox}>
            <div className={styles.iconWrapper}>
              <img src={socialFiIcon6} alt="Beta Icon" />
            </div>
            <div className={styles.contentWrapper}>
              <h4>Phase 1 Beta: Q3, 2025</h4>
              <ul>
                <li>Decentralized social media only. Web3 integration in Phase 2.</li>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default IncomeOpportunities;